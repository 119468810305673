import React, { useState, Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import * as yup from "yup";
import EmailLink from "./EmailLink";
import Arrow from "../images/graphic-arrow-btn-white.inline.svg";

import "./ContactForm.scss";

export interface ContactFormProps {
  name: string;
  useNetlify: boolean;
  className?: string;
  setSuccessNameFunc?: Dispatch<SetStateAction<string>>;
}

export interface IFormData {
  name: string;
  email: string;
  message: string;
}

const ContactForm: React.FC<ContactFormProps> = ({
  name,
  useNetlify,
  className,
  setSuccessNameFunc,
}) => {
  const netlifyAttributes = useNetlify
    ? { "data-netlify": true, "netlify-honeypot": "bot-field" }
    : {};

  const [formData, setFormData] = useState<IFormData | undefined>(undefined);
  const [sendAttempted, setSendAttempted] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<
    string | React.ReactNode | undefined
  >(undefined);

  const encode = (data) =>
    Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");

  const formDataValid = () => {
    const schema = yup.object().shape({
      name: yup.string().required(),
      email: yup.string().email().required(),
      message: yup.string().required(),
    });

    return schema
      .isValid(formData)
      .then((valid) => valid)
      .catch((_error) => false);
  };

  const handleSubmit = async (e) => {
    setSendAttempted(true);

    if (await formDataValid()) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": name, ...formData }),
      })
        .then(() => setSuccessNameFunc(formData.name))
        .catch((_error) =>
          setValidationMessage(
            <span>
              A server error occurred. Please try again or email us directly at{" "}
              <EmailLink address="contact@ideas42ventures.com" displayEmail />.
            </span>
          )
        );
    } else {
      setValidationMessage("All fields required.");
    }

    e.preventDefault();
  };

  const handleChange = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (sendAttempted && (await formDataValid())) {
      setValidationMessage(undefined);
    }
  };

  const labelClasses = (fieldName) => {
    const validationClass =
      validationMessage && !formData?.[fieldName] ? "invalid-field" : "";
    return classNames(fieldName, validationClass);
  };

  return (
    <section className={classNames("contact-form", className)}>
      <form name={name} onSubmit={handleSubmit} {...netlifyAttributes}>
        <div className="form-item g-max-2">
          <label htmlFor="name" className={labelClasses("name")}>
            <div className="label__text">Your name</div>
            <input
              type="text"
              name="name"
              className="input--text"
              placeholder="Your name here"
              onChange={handleChange}
              data-testid="name-input"
            />
          </label>
        </div>
        <div className="form-item g-max-2">
          <label htmlFor="email" className={labelClasses("email")}>
            <div className="label__text">Email address</div>
            <input
              type="email"
              name="email"
              className="input--email"
              placeholder="email@domain.com"
              onChange={handleChange}
              data-testid="email-input"
            />
          </label>
        </div>
        <div className="form-item g-max-2">
          <label htmlFor="message" className={labelClasses("message")}>
            <div className="label__text">Message</div>
            <textarea
              name="message"
              className="input--textarea"
              rows={16}
              placeholder="Your message here"
              onChange={handleChange}
              data-testid="message-input"
            />
          </label>
        </div>
        <div className="validation-message">
          {validationMessage && sendAttempted ? validationMessage : " "}
        </div>
        <button className="btn submit-btn" type="submit">
          Send
          <span className="btn__arrow">
            <Arrow />
          </span>
        </button>
      </form>
    </section>
  );
};

export default ContactForm;
