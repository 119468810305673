import { Link } from "gatsby";
import React from "react";
import HeadingAnchor from "./HeadingAnchor";

import "./ContactSuccess.scss";

export interface ContactSuccessProps {
  name: string;
}

const ContactSuccess: React.FC<ContactSuccessProps> = ({ name }) => (
  <section className="contact-success" data-testid="contact-success">
    <HeadingAnchor level="2" text={`Thank you ${name}!`} id="thank-you" />
    <p>
      Your message has been sent. We’ll get back to you as soon as we can. In
      the mean time, check out our <Link to="/studio">current cohorts</Link> or{" "}
      <a
        href="https://ideas42ventures.us1.list-manage.com/subscribe/post?u=f28b0f7c2bec71e9dc4a14f7f&id=69350d4819&SIGNUP=contact"
        target="_blank"
        rel="noreferrer"
      >
        sign up for our mailing list.
      </a>
    </p>
  </section>
);

export default ContactSuccess;
