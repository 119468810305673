import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import Masthead from "../components/Masthead";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import ContactSuccess from "../components/ContactSuccess";

const ContactPage: React.FC<DefaultPageProps> = ({ location, data }) => {
  const [successName, setSuccessName] = useState(undefined);

  const pageTitle = "ideas42 Venture Studio - Contact";
  const introMessage =
    "We’d love to hear from you, whether your interested in applying to the next cohort or how we work. Use the form below to send us an email.";

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
      </Helmet>
      <Masthead>
        <Hero
          tag="Contact us"
          title="Get in touch"
          body={
            successName ? <ContactSuccess name={successName} /> : introMessage
          }
          bodyClass="g-max-2-half"
        />
        {!successName && (
          <>
            <div className="masthead__graphic--contact" />
            <ContactForm
              name="studio-contact-page"
              className="standard-restricted-width"
              setSuccessNameFunc={setSuccessName}
              useNetlify
            />
          </>
        )}
      </Masthead>
    </Layout>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query ContactPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <ContactPage data={data} {...props} />}
  />
);
